<template>
    <div class="authority main">
        <ul class="tabs">
            <li>权限管理</li>
        </ul>
        <div class="container">
            <div class="filter">
                <div class="left">
                    <el-button size="small" type="primary" class="button_108px" @click="syncDingding"
                           v-if="power_power.power.edit">同步钉钉人员
                    </el-button>
                    <projectEdieSwitch></projectEdieSwitch>
                    <el-input size="small" v-model="name" placeholder="搜索..." @input="search" clearable
                            style="width: 250px;margin-left: 20px"></el-input>
                </div>
                <div class="right">
                    <el-button size="small" type="primary" icon="el-icon-alarm-clock" @click="editRemind">
                        项目月报催办
                    </el-button>
                </div>
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="nickname" label="姓名" align="center"></el-table-column>
                    <!--<el-table-column label="角色" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.part">{{initRole(scope.row.part)}}</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="查看项目范围" align="center">
                        <template slot-scope="scope">
                            <span>{{initProRange(scope.row)}}</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="权限" align="center" width="450">
                        <template slot-scope="scope">
                            <div v-if="initPower(scope.row).length>0" style="width:100%;">
                                <div v-for="(power,index) in initPower(scope.row)" :key="index+1"
                                     style="text-align: left;">{{ power }}
                                    <!--<span style="color:#0db829;margin:0 5px;" v-if="index!==initPower(scope.row).length-1">|</span>-->
                                </div>
                            </div>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="所属地区" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.area">{{initArea(scope.row.area)}}</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_start==2" style="color:#0db829;">已启用</span>
                            <span v-else style="color:#ff6a6a;">已禁用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="220" v-if="power_power.power.edit">
                        <template slot-scope="scope">
                            <el-button size="mini" class="button_58px" type="danger" v-if="scope.row.is_start===2"
                                       @click="startOff(2,scope.row)">禁用
                            </el-button>
                            <el-button size="mini" class="button_58px" type="success" v-else
                                       @click="startOff(1,scope.row)">启用
                            </el-button>
                            <setPower :row="scope.row" @refresh="staffLists(true)"
                                      v-if="scope.row.is_start===2"></setPower>
                            <el-button size="mini" class="button_58px" style="margin-left: 10px" type="warning"
                                       @click="simulatedLogin(scope.row.userid)"
                                       v-if="$store.state.user.current_user.userid==='265659300821760400'||$store.state.user.current_user.userid==='090133404330725926'||$store.state.user.current_user.userid==='4957618878'||$store.state.user.current_user.userid==='09013340431230026'||$store.state.user.current_user.userid==='2656593008843648'||$store.state.user.current_user.userid==='49576124172541'">
                                模拟
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>

        <remind_comp v-if="showRemind" :show="showRemind" @close="showRemind=false" />
    </div>
</template>

<script>
import api from '@/api/methods/index';
import comPower from '@/mixins/comPower.js';
import setPower from '@c/authority/set_power2.vue';
import projectEdieSwitch from '@c/authority/project_edit_switch.vue';
import computedData from '@/mixins/computedData.js';
import myPublic from '@/utils/public';
import remind_comp from './remind.vue';

export default {
    mixins: [comPower, computedData],
    components: {setPower, projectEdieSwitch, remind_comp},
    data() {
        return {
            tableData: [], page: 1, pagesize: 15, total: 0, loading: false, name: '',showRemind:false
        };
    },
    computed: {
        //回显查看项目范围
        initProRange: () => (obj) => {
            if (obj.part) {
                if (obj.all_project && Number(obj.all_project) !== 0) {
                    return '所有项目';
                } else if (obj.area && Number(obj.area)) {
                    return '所在县项目';
                } else if (obj.fuze_users === '293800591923653964') {
                    return '联络小组负责人为 孙金峤 的项目';
                } else if (obj.fuze_users === '186317032239809603') {
                    return '联络小组负责人为 黄小亮 的项目';
                } else {
                    return '本人作为联络小组负责人的项目';
                }
            } else {
                return '无';
            }
        },
        power_power() {
            return this.$store.state.user.current_user.power_power;
        }
    },
    created() {
        this.staffLists();
    },
    methods: {
        staffLists(refresh = false) {
            this.loading = !refresh;
            if (!refresh) {
                this.tableData = [];
            }
            let param = {page: this.page, pagesize: this.pagesize};
            if (this.name) {
                param.name = this.name;
            }
            api.authority.staffLists(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    this.tableData = res.data.lists || [];
                }
                this.loading = false;
            });
        },
        pageChange(page) {
            this.page = page;
            this.staffLists();
        },
        search() {
            this.page = 1;
            this.staffLists();
        },
        startOff(type, row) {
            if (this.$store.state.user.current_user.userid === row.userid) {
                this.$message.error('不能禁用本人!');
                return;
            }
            let param = {userid: row.user_id, type: type};
            api.authority.startOff(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: `人员${type === 1 ? '启用' : '禁用'}成功`,
                        type: 'success'
                    });
                    this.staffLists();
                }
            });
        },
        syncDingding() {
            api.login.syncUsers({}).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '同步钉钉人员成功',
                        type: 'success'
                    });
                    this.page = 1;
                    this.staffLists();
                }
            });
        },
        simulatedLogin(id) {
            api.login.simulatedLogin({user_id: id}).then(data => {
                if (data.data.state == 1) {
                    // this.$store.commit('user/SETTOKEN', data.data.data.token);
                    let user_info = myPublic.init_power(data.data.data.info);
                    console.log(user_info, '模拟登录返回的user_info');
                    this.$store.commit('user/SETCURRENTUSER', user_info);
                    this.$store.commit('user/SETCORP', data.data.data.corp);
                    // this.$store.commit('user/SETfIRSTGO', 1);
                    console.log('模拟登录成功');
                    api.project.getConfig({token: data.data.data.token}).then(res => {
                        if (res.data.state == 1) {
                            this.$store.commit('user/SETPROJECTEDIT', res.data.data);
                        }
                    });
                    this.$message({
                        message: `模拟${user_info.nickname}登录成功`,
                        type: 'success'
                    });
                }
            });
        },
        editRemind() {
            this.showRemind = true;
        }
    }
};
</script>

<style scoped lang="less">
.authority {
    .filter {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>