<template>
    <div class="set_power2">
        <el-button type="primary" class="button_58px" size="mini" @click="open">设置</el-button>
        <el-dialog title="设置" :visible.sync="show" width="1200px">
            <div class="table">
                <el-table :data="powerList" height="100%">
                    <el-table-column label="权限模块" align="center">
                        <template slot-scope="scope">
                            <span>{{ initModelName(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作权限" align="center" width="410">
                        <template slot-scope="scope">
                            <div class="group_checkbox"
                                 :class="scope.row==='first_page_power'?'one_box':scope.row==='task_power'||scope.row==='shi_project_project_power'||scope.row==='chubei_project_power'?'more_box':'two_box'">
                                <div class="check_box"
                                     :class="{active:value===1}"
                                     v-for="(value,key) in user_info[scope.row].power" :key="key"
                                     @click="checkPower(scope.row,key,value)">
                                    <div class="icon">
                                        <img class="default" src="../../assets/img/checkbox.png" alt="">
                                        <img class="active" src="../../assets/img/checkbox_active.png" alt="">
                                    </div>
                                    <div class="text">{{ initPowerName(scope.row, key) }}</div>
                                </div>
                                <div class="check_box no_data" v-for="v in 2" :key="v+'v'"
                                     v-if="scope.row==='shi_project_project_power'"></div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属地区" align="center">
                        <template slot-scope="scope">
                            <div class="area">
                                <el-select size="mini" v-model="user_info[scope.row].area"
                                           popper-class="global_select_popper"
                                           placeholder="无(默认)" clearable
                                           v-if="user_info[scope.row].area!==undefined&&user_info[scope.row].area!==null"
                                           :disabled="!user_info[scope.row].power.look">
                                    <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="button">
                <div class="phone_box">
                    <span>手机号:</span>
                    <el-input size="small" v-model="user_info.phone"></el-input>
                </div>
                <el-button class="button_88px" size="small" type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/**
 * 5.26日约定权限配置(方腾,李晓明)
 * 每个模块可设置查看以及编辑等权限
 * 除了任务管理和项目管理外 , 可编辑包含对应模块下的所有操作
 * 如果有关于所属地区的模块, 各个模块都可以设置对应的所属地区
 * 所有地区:查看所有地区的项目  州本级:查看州本级地区下登录人作为联络小组负责人的项目  其他县:查看所在县的所有项目
 * 查看权限和其他权限联动, 选择了其他权限默认勾选查看权限, 取消查看权限则取消该模块下的所有权限
 */
import api from '@/api/methods/index';
import comPower from '@/mixins/comPower.js';
import myPublic from '@/utils/public.js';

export default {
    mixins: [comPower],
    props: ['row'],
    data() {
        return {
            show: false,
            powerList: [
                'first_page_power', 
                'task_power', 
                'shi_project_project_power', 
                'three_cross_power', 
                'qu_project_power', 
                'zichou_project_power', 
                'fund_project_power',
                // 'shenji_project_power', 
                // 'weituo_power', 
                // 'pinggu_project_power', 
                // 'chubei_project_power', 
                // 'statistics_power', 
                'power_power',
                'sz_guoluo',
                'xygk_power'
            ],
            user_info: {},//处理后的用户信息
            areaList: [
                {value: -1, 
                    label: '所有地区'},
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ]
        };
    },
    methods: {
        open() {
            this.user_info = myPublic.init_power(this.row);
            this.show = true;
        },
        save() {
            if (this.user_info.phone) {
                const phoneTest = myPublic.validateMobileNumber(this.user_info.phone)
                if (!phoneTest) {
                    this.$message({
                        message: '手机号格式不正确！请重新输入',
                        type: 'warning'
                    });
                    return
                }
            }
   
            let param = {
                userid: this.user_info.userid,
                part: this.user_info.part,
                area: this.user_info.area,
                all_project: this.user_info.all_project,
                fuze_users: this.user_info.fuze_users,
                see_area: this.user_info.see_area,
                first_page_power: JSON.stringify(this.user_info.first_page_power),
                task_power: JSON.stringify(this.user_info.task_power),
                shi_project_project_power: JSON.stringify(this.user_info.shi_project_project_power),
                qu_project_power: JSON.stringify(this.user_info.qu_project_power),
                zichou_project_power: JSON.stringify(this.user_info.zichou_project_power),
                fund_project_power: JSON.stringify(this.user_info.fund_project_power),
                shenji_project_power: JSON.stringify(this.user_info.shenji_project_power),
                weituo_power: JSON.stringify(this.user_info.weituo_power),
                pinggu_project_power: JSON.stringify(this.user_info.pinggu_project_power),
                chubei_project_power: JSON.stringify(this.user_info.chubei_project_power),
                statistics_power: JSON.stringify(this.user_info.statistics_power),
                power_power: JSON.stringify(this.user_info.power_power),
                three_cross_power: JSON.stringify(this.user_info.three_cross_power),
                sz_guoluo: JSON.stringify(this.user_info.sz_guoluo),
                phone: this.user_info.phone,
                xygk_power: JSON.stringify(this.user_info.xygk_power),
            };
            api.authority.editPartArea(param).then(res => {
                if (res.data.state == 1) {
                    if (this.$store.state.user.current_user.userid === param.userid) {
                        let current_user = JSON.parse(JSON.stringify(this.$store.state.user.current_user));
                        current_user.first_page_power = this.user_info.first_page_power;
                        current_user.task_power = this.user_info.task_power;
                        current_user.shi_project_project_power = this.user_info.shi_project_project_power;
                        current_user.qu_project_power = this.user_info.qu_project_power;
                        current_user.zichou_project_power = this.user_info.zichou_project_power;
                        current_user.fund_project_power = this.user_info.fund_project_power;
                        current_user.shenji_project_power = this.user_info.shenji_project_power;
                        current_user.weituo_power = this.user_info.weituo_power;
                        current_user.pinggu_project_power = this.user_info.pinggu_project_power;
                        current_user.chubei_project_power = this.user_info.chubei_project_power;
                        current_user.statistics_power = this.user_info.statistics_power;
                        current_user.power_power = this.user_info.power_power;
                        current_user.three_cross_power = this.user_info.three_cross_power;
                        current_user.sz_guoluo = this.user_info.sz_guoluo;
                        current_user.phone = this.user_info.phone;
                        current_user.xygk_power = this.user_info.xygk_power;
                        this.$store.commit('user/SETCURRENTUSER', current_user);
                    }
                    this.$message({
                        message: `权限设置成功`,
                        type: 'success'
                    });
                    this.$emit('refresh');
                    this.show = false;
                }
            });
        },
        checkPower(model_name, power_name, value) {
            let power = this.user_info[model_name].power;
            let new_value = value ? 0 : 1;
            this.$set(power, power_name, new_value);
            if (model_name !== 'task_power') {
                if (power_name === 'look' && new_value === 0) {
                    for (let key in power) {
                        power[key] = 0;
                    }
                    if (this.user_info[model_name].area !== null && this.user_info[model_name].area !== undefined) {
                        this.$set(this.user_info[model_name], 'area', '');
                    }
                }
                if (new_value === 1 && power.look === 0) {
                    this.$set(power, 'look', 1);
                }
            }
        }
    }
};
</script>

<style scoped lang="less">
.set_power2 {
    display: inline-block;
    margin-left: 10px;

    /deep/ .el-dialog__body {
        .table {
            flex: 1;
            min-height: 0;

            .group_checkbox {
                display: flex;
                justify-content: space-between;

                &.one_box {
                    .check_box {
                        flex: 0 0 70px;
                    }
                }

                &.two_box {
                    flex: 0 0 200px;

                    .check_box {
                        flex: 0 0 70px;
                    }
                }

                &.more_box {
                    flex: 0 0 350px;
                    flex-wrap: wrap;

                    .check_box {
                        flex: 0 0 105px;
                    }
                }

                .check_box {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;

                    .icon {
                        flex: 0 0 16px;
                        height: 16px;
                        position: relative;
                        margin-right: 5px;

                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            transition: opacity 0.3s;

                            &.default {
                                opacity: 1;
                            }

                            &.active {
                                opacity: 0;
                            }
                        }
                    }

                    .text {
                        flex: 1;
                        min-width: 0;
                        text-align: left;
                        color: #999;
                        transition: color 0.3s;
                    }

                    &.no_data {
                        height: 0;
                        overflow: hidden;
                    }

                    &.active {
                        .text {
                            color: #3396fb;
                        }

                        img {
                            &.default {
                                opacity: 0;
                            }

                            &.active {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .button {
            border-top: none;
            display: flex;
            justify-content: space-between;
            padding: 0 20px;

            .phone_box {
                display: flex;
                align-items: center;

                span {
                    white-space: nowrap;
                    margin-right: 10px;
                }
            }
        }
    }
}
</style>