// 地区列表
export const areaList = [
	{ value: 1, label: "州本级" },
	{ value: 2, label: "玛沁县" },
	{ value: 5, label: "甘德县" },
	{ value: 6, label: "达日县" },
	{ value: 4, label: "班玛县" },
	{ value: 3, label: "久治县" },
	{ value: 7, label: "玛多县" },
];
