<template>
	<el-dialog
		title="项目月报催办设置"
		:visible="show"
		width="900px"
		@close="emit('close')"
	>
		<div class="content">
			<div class="line">
				<div class="left">
					<span class="label">开始时间</span>
					<span
						class="button"
						:class="{ active: start_time_type == 1 }"
						@click="setStartTimeType(1)"
					>
						本月
					</span>
					<span
						class="button"
						:class="{ active: start_time_type == 2 }"
						@click="setStartTimeType(2)"
					>
						下月
					</span>
				</div>
				<div class="right">
					<span class="one">月</span>
					<el-select
						v-model="start_time_date"
						size="small"
						@change="changeStartTimeDate"
					>
						<el-option v-for="i in 31" :key="i" :label="i" :value="i"></el-option>
					</el-select>
					<span class="two">号</span>
				</div>
			</div>
			<div class="line">
				<div class="left">
					<span class="label">结束时间</span>
					<span
						class="button"
						:class="{ active: end_time_type == 1, disabled: start_time_type == 2 }"
						@click="setEndTimeType(1)"
					>
						本月
					</span>
					<span
						class="button"
						:class="{ active: end_time_type == 2 }"
						@click="setEndTimeType(2)"
					>
						下月
					</span>
				</div>
				<div class="right">
					<span class="one">月</span>
					<el-select v-model="end_time_date" size="small">
						<el-option v-for="i in 31" :key="i" :label="i" :value="i"></el-option>
					</el-select>
					<span class="two">号</span>
				</div>
			</div>

			<div class="line user">
				<span class="label">催办对象</span>
				<div class="right">
					<div class="item" v-for="it in areaList">
						<span>{{ it.label }}</span>
						<div class="user_box">
							<div class="user_list">
								<span v-for="it in user?.[it.value] || []">{{ it.nickname }}</span>
							</div>
							<div class="add" @click="selectUsers(it.value)">
								<i class="el-icon-plus"></i>
								<span>添加成员</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="left">
				项目月报催办将用
				<span>短信形式</span>
				在
				<span>
					{{ start_time_type == 1 ? "本月" : "下月" }}第
					{{ start_time_date || "/" }}
					天</span
				>
				开始催办并
				<span
					>每天催办一次，直至
					{{ end_time_type == 1 ? "本月" : "下月" }}
					第{{ end_time_date || "/" }}天</span
				>
			</div>
			<el-button size="small" @click="emit('close')">取消</el-button>
			<el-button size="small" type="primary" @click="saveRemindConfig">
				保存
			</el-button>
		</div>
	</el-dialog>
</template>

<script setup>
import { ref } from "vue";
import api from "@/api/methods/index.js";
import { areaList } from "@/variable/area.js";
import peoplePicker from "@/utils/people_picker";
import { Message } from "element-ui";

const props = defineProps({
	show: {
		type: Boolean,
	},
});
const emit = defineEmits(["close"]);

const start_time_type = ref(1);
const start_time_date = ref();
const setStartTimeType = (type) => {
	start_time_type.value = type;
	if (type == 2) {
		end_time_type.value = 2;

		if (end_time_date.value < start_time_date.value) {
			end_time_date.value = undefined;
		}
	}
};
const changeStartTimeDate = () => {
	if (
		start_time_type.value == end_time_type.value &&
		start_time_date.value > end_time_date.value
	) {
		end_time_date.value = undefined;
	}
};

const end_time_type = ref(1);
const end_time_date = ref();
const setEndTimeType = (type) => {
	if (start_time_type.value == 2 && type == 1) return;
	end_time_type.value = type;
};

const user = ref({});
const selectUsers = (id) => {
	const old_users = user.value?.[id]?.map?.((it) => it.userid) || [];
	peoplePicker.peoplePicker(
		old_users,
		(res) => {
			let users = [];
			res.users.forEach((user) => {
				if (user.emplId) {
					users.push({
						avatar: user.avatar,
						nickname: user.name,
						userid: user.emplId,
					});
				}
			});
			user.value = {
				...user.value,
				[id]: users,
			};
			user.value[id] = users;
			console.log("user", user.value);
		},
		true
	);
};

const getRemindConfig = async () => {
	const msg = await api.authority.getRemindConfig();
	if (msg.data.state == 1) {
		const data = msg.data.rows;
		start_time_type.value = data.start_time_type;
		start_time_date.value = data.start_time_date;
		end_time_type.value = data.end_time_type;
		end_time_date.value = data.end_time_date;
		user.value = data.area_config;
	}
};
getRemindConfig();

const saveRemindConfig = async () => {
	if (!start_time_date.value || !end_time_date.value) {
		Message({
			type: "warning",
			message: "开始/结束时间不能为空",
		});
		return;
	}

	const user_id = Object.keys(user.value).reduce((pre, now) => {
		pre[now] = user.value[now].map((it) => it.userid).join(",");
		return pre;
	}, {});
	const data = {
		start_time_type: start_time_type.value,
		start_time_date: start_time_date.value,
		end_time_type: end_time_type.value,
		end_time_date: end_time_date.value,
		area_config: JSON.stringify(user_id),
	};
	const msg = await api.authority.saveRemindConfig(data);
	if (msg.data.state == 1) {
		Message({
			type: "success",
			message: "保存成功",
		});
	}
};
</script>

<style lang="less" scoped>
.content {
	--color: #3496fb;
	padding: 10px 20px;

	.label {
		width: 80px;
		flex-shrink: 0;
	}

	.button {
		border: 1px solid rgb(221, 221, 221) !important;
		padding: 4px 10px;
		border-radius: 4px;
		cursor: pointer;

		&.active {
			color: white;
			border-color: var(--color) !important;
			background: var(--color);
		}

		&.disabled {
			cursor: not-allowed;
		}
	}
	.button + .button {
		margin-left: 10px;
	}

	.line {
		display: flex;
		justify-content: space-between;

		.left,
		.right {
			display: flex;
			align-items: center;
		}

		.right {
			.one {
				margin-right: 10px;
			}
			.two {
				margin-left: 10px;
			}
		}
	}
	.line + .line {
		margin-top: 20px;
	}
}

.footer {
	display: flex;
	align-items: center;
	padding: 8px 20px;
	border-top: 1px solid rgb(221, 221, 221);

	.left {
		flex: 1;
		span {
			color: #3496fb;
		}
	}
}

.user {
	.label {
		line-height: 40px;
	}
	.right {
		flex: 1;
		display: flex;
		flex-direction: column;

		.item {
			display: flex;
			align-items: center;
			width: 100%;

			.user_box {
				flex: 1;
				display: flex;
				align-items: center;
				margin-left: 20px;
				border: 1px solid rgb(221, 221, 221);
				min-height: 34px;
				border-radius: 4px;
				padding: 0 10px;
				box-sizing: border-box;
				justify-content: space-between;

				.user_list {
					flex: 1;
					display: flex;
					flex-wrap: wrap;

					span {
						margin-right: 4px;
						white-space: nowrap;
					}
				}

				.add {
					color: #3496fb;
					cursor: pointer;
					margin-left: 10px;
					i {
						font-weight: bold;
						margin-right: 4px;
					}
				}
			}
		}

		.item + .item {
			margin-top: 10px;
		}
	}
}
</style>
