import myPublic from '@/utils/public.js'
export default {
    computed:{
        /*initRole:()=>(e)=>{
            switch (Number(e)) {
                case 1:
                    return '主管理员';
                case 2:
                    return '联络小组';
                case 3:
                    return '财务';
                case 4:
                    return '项目公司管理人员';
            }
        },
        initPower:()=>(e)=>{
            switch (Number(e)) {
                case 1:
                    return [
                        // '首页','项目管理','市级项目资金','计划外自筹项目','计划外报表','储备项目','统计分析','权限管理',
                        '首页概况','任务管理','市项目管理','区项目管理','自筹项目管理','市级项目资金',
                        '项目审计','项目检查','项目评估','储备项目','统计分析','权限管理'
                    ];
                case 2:
                    return [
                        // '首页概况',
                        // '项目管理(项目初始化，删除，查看，编辑，录入审计，录入问题，录入评估，录入月报，查看月报，删除月报，编辑月报)',
                        // '市级项目资金(确认资金到账)',
                        // '计划外自筹项目','计划外报表','储备项目','统计分析'
                        '首页概况','任务管理','市项目管理','区项目管理','自筹项目管理','市级项目资金',
                        '项目审计','项目检查','项目评估','储备项目','统计分析'
                    ];
                case 3:
                    return [
                        // '市级项目资金（拨款）',
                        '任务管理','市级项目资金'
                    ];
                case 4:
                    return [
                        // '月报项目管理'
                        '任务管理','市项目管理'
                    ];
            }
        },*/
    },
    methods:{
        //model_name:模块名称, power_name:权限名称
        initPowerName(model_name,power_name){
            if(model_name==='task_power'){
                if(power_name==='look'){
                    return '查看全部任务';
                }
                if(power_name==='register'){
                    return '任务督办';
                }
                if(power_name==='appraise'){
                    return '任务评价';
                }
            }else if(model_name==='shi_project_project_power'){
                if(power_name==='look'){
                    return '查看项目';
                }
                if(power_name==='month_form'){
                    return '新增月报';
                }
                if(power_name==='edit'){
                    return '修改基础信息';
                }
                if(power_name==='upload'){
                    return '上传资料';
                }
                if(power_name==='change'){
                    return '变更项目';
                }
                if(power_name==='stop'){
                    return '终止项目';
                }
                if(power_name==='delete'){
                    return '删除项目';
                }
            }else{
                if(power_name==='look'){
                    return '可查看';
                }
                if(power_name==='edit'){
                    return '可编辑';
                }
                if(power_name==='up'){
                    return '项目审核';
                }
            }
        },
        initModelName(name){
            switch (name) {
                case 'first_page_power':
                    return '首页';
                case 'task_power':
                    return '任务管理';
                case 'shi_project_project_power':
                    return '市项目管理';
                case 'qu_project_power':
                    return '区项目管理';
                case 'zichou_project_power':
                    return '自筹项目管理';
                case 'fund_project_power':
                    return '市级项目资金';
                case 'shenji_project_power':
                    return '项目审计';
                case 'weituo_power':
                    return '委托服务管理';
                case 'pinggu_project_power':
                    return '项目评估';
                case 'chubei_project_power':
                    return '储备项目';
                case 'statistics_power':
                    return '统计分析';
                case 'power_power':
                    return '权限管理';
                case 'three_cross_power':
                    return '三交活动报备';
                case 'sz_guoluo':
                    return '数字果洛'
                case 'xygk_power':
                    return '县域概况'
            }
        },
        //列表权限回显
        //首页：可查看 | 任务管理：查看全部任务、任务督办、任务评价 | 市项目管理：查看项目、终止项目（所属地区：无）
        initPower(obj){
            let user_info = myPublic.init_power(obj);
            let power=[];
            let self=this;
            function initArea(area){
                if(area===1){
                    return '州本级';
                }else if(area===2){
                    return '玛沁县';
                }else if(area===3){
                    return '久治县';
                }else if(area===4){
                    return '班玛县';
                }else if(area===5){
                    return '甘德县';
                }else if(area===6){
                    return '达日县';
                }else if(area===7){
                    return '玛多县';
                }else if(area===-1){
                    return '所有地区';
                }else{
                    return '无'
                }
            }
            function init(model_name){
                let power_names = [];
                let model_power = '';
                if(user_info[model_name]){
                    if(user_info[model_name].power){
                        for(let key in user_info[model_name].power){
                            if(user_info[model_name].power[key]){
                                power_names.push(self.initPowerName(model_name,key))
                            }
                        }
                    }
                    if(power_names.length>0){
                        if(user_info[model_name].area!==undefined&&user_info[model_name].area!==null){
                            model_power=`${self.initModelName(model_name)}：${power_names.join('、')}（所属地区：${initArea(user_info[model_name].area)}）`;
                        }else{
                            model_power=`${self.initModelName(model_name)}：${power_names.join('、')}`;
                        }
                    }
                }
                return model_power;
            }
            power=[
                init('first_page_power'),
                init('task_power'),
                init('shi_project_project_power'),
                init('three_cross_power'),
                init('qu_project_power'),
                init('zichou_project_power'),
                init('fund_project_power'),
                init('shenji_project_power'),
                init('weituo_power'),
                init('pinggu_project_power'),
                init('chubei_project_power'),
                init('statistics_power'),
                init('power_power'),
                init('sz_guoluo'),
                init('xygk_power')
            ];
            return power.filter(item=>item);
        },
    }
}