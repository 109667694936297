<template>
    <div class="project_edit_switch" v-if="$store.state.user.current_user.userid==='2936464123483159'">
        <div class="label">新增、编辑项目</div>
        <div class="switch">
            <div class="item" :class="{active:type===1}" @click="click(1)">开</div>
            <div class="item" :class="{active:type===2}" @click="click(2)">关</div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    export default {
        data(){
            return{
                type:2
            }
        },
        created(){
            this.type=JSON.parse(JSON.stringify(Number(this.$store.state.user.project_edit_data.project_edit)));
        },
        methods:{
            click(type){
                if(type===this.type) return;
                let project_edit_data = JSON.parse(JSON.stringify(this.$store.state.user.project_edit_data));
                api.project.editConfig({id:this.$store.state.user.project_edit_data.id,type:type}).then(res=>{
                    if (res.data.state == 1) {
                        this.$message({
                            message: `${type===1?'开启':'关闭'}成功`,
                            type: 'success'
                        });
                        project_edit_data.project_edit=type;
                        this.type=type;
                        this.$store.commit('user/SETPROJECTEDIT',project_edit_data);
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
.project_edit_switch{
    display:flex;
    align-items: center;
    width:250px;
    margin-left: 20px;
    .label{
        flex:1;
        min-width: 0;
        text-align: right;
    }
    .switch{
        flex:0 0 120px;
        display:flex;
        margin-left: 10px;
        .item{
            flex:1;
            background:#eee;
            color:#666;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s;
            &.active{
                background:#3396fb;
                color:#fff;
            }
            &:first-of-type{
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-of-type{
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
</style>